<!--  This component uses the zxcvbn library to estimate password strength -->
<template>
  <div class="relative select-none text-gray-700 dark:text-white">
    <div class="flex flex-row place-items-center h-8">
      <p class="text-sm font-medium pr-2">
        Password strength
      </p>
      <div v-if="score > 0">
        <ShieldCheckIcon v-if="score >= 4" class="h-8 w-8" :class="description.iconColor" />
        <ShieldExclamationIcon v-else class="h-8 w-8" :class="description.iconColor" />
      </div>
    </div>
    <BaseProgressBar
      :value="score"
      :max="descriptions?.length"
      :color="description.color"
    />
    <p class="my-8 h-4 mt-1 text-sm">
        {{ feedback.warning }}
        {{ crackTimesDisplay }}
    </p>
    <div class="my-8 mt-1 text-sm pb-2" v-if="feedback.suggestions.length">
      <p class="font-medium">Suggestions:</p>
    <ul class="list-disc px-4">
      <li v-for="suggestion, idx in feedback.suggestions" :key="idx">
        {{suggestion}}
      </li>
    </ul>
    </div>
  </div>
</template>

<script>
import { computed, watch } from "vue";
import { zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core";
import zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import zxcvbnEnPackage from "@zxcvbn-ts/language-en";
import BaseProgressBar from "./BaseProgressBar.vue";
import { ShieldCheckIcon } from "@heroicons/vue/solid/esm"
import { ShieldExclamationIcon } from "@heroicons/vue/solid/esm"

export default {
  components: {
    BaseProgressBar,
    ShieldCheckIcon,
    ShieldExclamationIcon
  },
  emits: ["passed", "failed"],
  setup(props, { emit }) {
    const options = {
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEnPackage.dictionary,
      },
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
      translations: zxcvbnEnPackage.translations,
    };
    zxcvbnOptions.setOptions(options);
    const score = computed(() => {
      const hasValue = props.value && props.value.length > 0;

      if (!hasValue) {
        return 0;
      }

      return zxcvbn(props.value).score + 1;
    });
    const feedback = computed(() => {
      return zxcvbn(props.value).feedback;
    })
    const descriptions = computed(() => [
      {
        color: "bg-red-600",
        iconColor: "text-red-600",
      },
      {
        color: "bg-orange-400",
        iconColor: "text-orange-400",
      },
      {
        color: "bg-yellow-400",
        iconColor: "text-yellow-400",
      },
      {
        color: "bg-green-400",
        iconColor: "text-green-400",
      },
      {
        color: "bg-green-600",
        iconColor: "text-green-600",
      },
    ]);
    const description = computed(() =>
      props.value && props.value?.length > 0
        ? descriptions.value[score.value - 1]
        : {
            color: "bg-transparent",
            label: "Start typing to check your password",
          }
    );
    const isPasswordStrong = computed(() => score.value >= 4);
    watch(isPasswordStrong, (value) => {
      value ? emit("passed") : emit("failed");
    });

    return { score, descriptions, description, feedback }
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  }
}
</script>