<template>
  <Loading key="loading" v-if="store.getters.isLoading" />
  <div key="register" v-else
    class="min-h-screen bg-background flex flex-col justify-center py-12 px-6 sm:px-6 lg:px-8 dark:bg-backgroundDark">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <success :message="alertMessage" v-if="showSuccess" />
                <danger :message="alertMessage" v-if="showDanger" /> 
      <div class="bg-primary pt-5 pb-8 shadow rounded-lg dark:bg-primaryDark" v-show="formHide" >
       
        <p class="pb-4 text-center sm:text-3xl text-2xl font-bold text-gray-900 dark:text-white">
          Create your account
        </p>
        <!-- Sign up with social media -->
        <!-- NOTE: Sign up with social media hasn't been implemented yet -->
        <!-- <div class="text-gray-700 dark:text-white items-center px-8 mb-4">
          <p class="text-sm pb-1 text-gray-500 dark:text-white">Sign up with:</p>
          <div class="grid grid-cols-3 gap-6 h-8">
            <div class="cursor-pointer flex justify-center border rounded-lg h-full hover:bg-gray-100 dark-hover:bg-gray-800">
              <font-awesome-icon icon="fa-brands fa-apple" class="self-center"/>
            </div>
            <div class="cursor-pointer flex justify-center border rounded-lg h-full hover:bg-gray-100 dark-hover:bg-gray-800">
              <font-awesome-icon icon="fa-brands fa-google" class="self-center"/>
            </div>
            <div class="cursor-pointer flex justify-center border rounded-lg h-full hover:bg-gray-100 dark-hover:bg-gray-800">
              <font-awesome-icon icon="fa-brands fa-facebook-f" class="self-center"/>
            </div>
          </div>
          <div class="my-6 grid grid-cols-3 sm:gap-1 items-center text-gray-400">
            <span class="border-b border-gray-400 sm:mr-1 mr-2" />
            <p class="flex flex-row sm:text-sm text-xs justify-center">or continue with</p>
            <span class="border-b border-gray-400 sm:ml-1 ml-2" />
          </div>
        </div> -->
        <form @submit.prevent="register" class="grid grid-flow-row gap-3" >
        <div class="px-8">
          <!-- Email -->
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700 dark:text-white">
              Email address
            </label>
            <div class="mt-1">
              <input v-model="state.email" id="email" name="email" type="email" autocomplete="email" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:text-white dark:bg-primaryDark" />
            </div>
          </div>
          <!-----Errors------>
          <div :class="{ error: v$.email.$errors.length }">
            <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
              <div class="error-msg text-red-600" v-if="error.$validator==='required'">Email is required</div>
              <div class="error-msg text-red-600" v-if="error.$validator==='email'">Enter valid email address</div>
            </div>
          </div>
        </div>
          <div class="pl-8">
            <label for="password" class="block text-sm font-medium text-gray-700 dark:text-white">
              Password
            </label>
            <div class="mt-1 flex flex-row mr-2">
              <input v-model="state.password1" id="password" name="password" :type="showPassword1 ? 'text' : 'password'"
                autocomplete="current-password" 
                class="appearance-none block w-full pl-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:text-white dark:bg-primaryDark" />
                <div class="flex justify-center text-sm pl-1" @click="showPassword1 = !showPassword1">
                  <font-awesome-icon v-if="showPassword1" icon="fa-eye" class="w-5 self-center text-gray-700 dark:text-white"/>
                  <font-awesome-icon v-else icon="fa-eye-slash" class="w-5 self-center text-gray-700 dark:text-white"/>
                </div>
            </div>
         <!-----Errors------>
          <div :class="{ error: v$.password1.$errors.length }">
            <div class="input-errors" v-for="error of v$.password1.$errors" :key="error.$uid">
              <div class="error-msg text-red-600" v-if="error.$validator==='required'">Password is required</div>
              <div class="error-msg text-red-600" v-if="error.$validator==='minLength'">Password must be minimum 8 characters</div>
            </div>
          </div>
          </div>
          <div class="pl-8">
            <label for="password2" class="block text-sm font-medium text-gray-700 dark:text-white">
              Confirm Password
            </label>
            <div class="mt-1 flex flex-row mr-2">
              <input v-model="state.password2" id="password2" name="password2" :type="showPassword2 ? 'text' : 'password'"
                autocomplete="current-password" 
                class="appearance-none block w-full pl-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:text-white dark:bg-primaryDark" />
                <div class="flex justify-center text-sm pl-1" @click="showPassword2 = !showPassword2">
                  <font-awesome-icon v-if="showPassword2" icon="fa-eye" class="w-5 self-center text-gray-700 dark:text-white"/>
                  <font-awesome-icon v-else icon="fa-eye-slash" class="w-5 self-center text-gray-700 dark:text-white"/>
                </div>
            </div>
          <!-----Errors------>
          <div :class="{ error: v$.password2.$errors.length }">
            <div class="input-errors" v-for="error of v$.password2.$errors" :key="error.$uid">
              <!-- <div class="error-msg text-red-600" v-if="error.$validator==='required'">Password is required</div> -->
              <div class="error-msg text-red-600" v-if=" error.$validator==='areEqual'">Password and Confirm Password should match</div>
            </div>
          </div>
          </div>
          <div class="px-8">
            <PasswordScore :value="state.password1" class="mt-2 mb-6" @passed="isPasswordStrong = true"
              @failed="isPasswordStrong = false" />
          </div>
          <div class="px-8">
            <button type="submit"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-backgroundDark dark:text-white"
             >
              Sign Up
            </button>
          </div>
        </form>
        <!--
        <div class="mt-6">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300"/>
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="px-2 bg-white text-gray-500">
                Or continue with
              </span>
            </div>
          </div>

          <div class="mt-6 grid grid-cols-3 gap-3">
            <div>
              <a href="#"
                 class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span class="sr-only">Sign in with Facebook</span>
                <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd"
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        clip-rule="evenodd"/>
                </svg>
              </a>
            </div>

            <div>
              <a href="#"
                 class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span class="sr-only">Sign in with Twitter</span>
                <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path
                      d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"/>
                </svg>
              </a>
            </div>

            <div>
              <a href="#"
                 class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span class="sr-only">Sign in with GitHub</span>
                <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd"
                        d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                        clip-rule="evenodd"/>
                </svg>
              </a>
            </div>
          </div>
          </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, email, helpers,minLength } from '@vuelidate/validators'
import Loading from "@/components/layout/Loading";
import PasswordScore from '../components/widgets/PasswordScore.vue';
import success from '@/components/widgets/alertMessage/success'
import danger from '@/components/widgets/alertMessage/danger'

// Custom equal password validation
const areEqual = (pw1Func) => (pw2) => {
  const pw1 = pw1Func()
  return pw1 === pw2
}

export default {
  components: { Loading, PasswordScore,success,danger },
  data() {
        return {
          alertMessage: "",
          showSuccess:false,
          showDanger:false,
          formHide:true,
        }
    },
  setup() {
    const store = useStore()
    
    const state = reactive({
      email: '',
      password1: '',
      password2: ''
    })

    const rules = {
      email: { required, email },
      password1: { required, minLength: minLength(8) },
      password2: { required, areEqual: helpers.withMessage('Passwords must match', areEqual(() => state.password1)) }
    }
    const v$ = useVuelidate(rules, state, { $lazy: false })
    const password = ref(state.password1)
    const isPasswordStrong = ref(false)
    const isPasswordStrongAndEqual = computed(() =>
      isPasswordStrong.value && (state.password1 === state.password2)
      )
    const isButtonDisabled = computed (() =>
      isPasswordStrongAndEqual.value && !v$.value.email.$invalid
    )
    const showPassword1 = ref(false)
    const showPassword2 = ref(false)
    return {
      v$,
      store,
      state,
      password,
      isPasswordStrong,
      isPasswordStrongAndEqual,
      isButtonDisabled,
      showPassword1,
      showPassword2
    }
  },
  methods: {
    async register() {
      this.showSuccess=false;
              this.showDanger=false;
      this.v$.$touch()
      if (this.v$.$error) return
     
      try {
        await this.store.dispatch('Register', this.state)
            .then(response => {
                         
              if ( response.success === true || response.success === 'true' ) {
                this.formHide=false;
                let success = response.success;
                let message=response.message+ ' Check your email to confirm your account';
                
                this.alertMessage=message;
                this.showSuccess=true;
                
                this.$router.push({ name: "Login",params: {success,message} });
            }else{
              console.log('SignInForm.handleSubmit responsesss: ', response);  // works
               this.alertMessage=response.message
               this.showDanger=true;
              
              }
        }).catch(error => {
            console.log('big problems: ', error);
            this.alertMessage=error.message
            this.showDanger=true;
         
          });
    } catch (error) {
        console.log("Error: SignInForm.handleSubmit", error);
        this.alertMessage=error
        this.showDanger=true;
        
     }
              
              this.state.email = ''
              this.state.password1 = ''
              this.state.password2 = ''
              this.v$.$reset()

     
    }
  }
}

</script>
