<template>
  <div class="flex h-3 border border-grey-30 rounded-xl">
    <div
      :style="{ width: `${width}%` }"
      :class="[color]"
      class="progress-bar flex h-full duration-100 ease-in-out rounded-xl"
    />
  </div>
</template>
<script>
import { computed } from 'vue';
export default {
  props: {
    max: {
      type: Number,
      required: true,
    },
    value: {
      // type: Number,
      required: true,
    },
    color: {
      type: String,
      default: 'bg-transparent',
    },
  },
  setup(props) {
    const width = computed(() => (props.value / props.max) * 100);
    return { width };
  },
}
</script>